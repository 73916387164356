import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query FourPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      phoneNumber
      email
      facebook
      twitter
      instagram
      linkedIn
    }
  }
`;

const gradient = {
  from: "#012c43",
  to: "#012c43"
};


const NotFoundPage = props => {
  const { data } = props;
  const site = (data || {}).site;
  return (
    <Layout textWhite={false} site={site} navPlacement={false}>
      <SEO 
        title="404: Not found" 
        gradient={gradient} 
        bodyAttr={{
          class: "leading-normal tracking-normal text-white gradient"
        }}  
      />
      <section className="full-height">
        <div className="container mx-auto">
          <img src="/img/404logo.png" alt="Linkedin icon and link"/>
        </div>
      </section>
    </Layout>
  )
};

export default NotFoundPage;
